// extracted by mini-css-extract-plugin
export var description = "_pageTeam-module--description--jp9Tf";
export var heading = "_pageTeam-module--heading---6U54";
export var jobList = "_pageTeam-module--jobList--Vr-nv";
export var jobcategory = "_pageTeam-module--jobcategory--IEPge";
export var org = "_pageTeam-module--org--BKH7w";
export var orgContets = "_pageTeam-module--orgContets--fZtiS";
export var orgFooter = "_pageTeam-module--orgFooter--EAYxj";
export var orgHeader = "_pageTeam-module--orgHeader--ZAIWT";
export var orgImage = "_pageTeam-module--orgImage--KIJwI";
export var orgList = "_pageTeam-module--orgList--TmK0h";
export var orgName = "_pageTeam-module--orgName--GDer7";
export var orgdescription = "_pageTeam-module--orgdescription--sQO15";
export var seledted = "_pageTeam-module--seledted--WCBud";
export var teams = "_pageTeam-module--teams--DbYaH";
export var title = "_pageTeam-module--title--9CDc5";