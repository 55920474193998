import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Animate from "../../components/animate"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as styles from "../../styles/_pageTeam.module.scss"
import { teamList, teamInfo, siteLink, teamLink, inactive, moreLink, teamLogo } from '../../styles/_teamList.module.scss'
import CAgroupLogo from "../../images/CAgroupLogo.svg";

const TeamPage = ({ data }) => {

  //職種を抽出
  let jobList = [];
  let jobCount = {};
  data.allMdx.nodes.map(node => {
    if (node.frontmatter.jobcategories) {
      node.frontmatter.jobcategories.map(jobcategory => (
        jobList.push(jobcategory)
      ))
    }
    return false
  }
  )
  jobList.forEach(function (i) {
    jobCount[i] = (jobCount[i] || 0) + 1;
  });
  const jobs = Object.keys(jobCount);

  //切り替え
  const [selectedJob, setJob] = useState('');
  const switchList = (name) => {
    setJob(name)
  }
  let selectedNode = data.allMdx.nodes.filter(
    node => selectedJob ? (node.frontmatter.jobcategories && node.frontmatter.jobcategories.includes(selectedJob)) : node
  )

  return (
    <Layout home={false}>
      <header className="content-header">
        <Animate className="fadeIn" triggerOnce={true}>
          <h1 className="title1" style={{ transitionDelay: `1s` }}><span>チームについて</span>Team</h1>
        </Animate>
      </header>
      <section className={styles.org}>
        <div className={styles.title}>
          <Animate className="fadeIn" triggerOnce={true}>
            <h2 className="title3">組織の役割</h2>
            <p>グループIT推進本部では、スタートアップ会社の立ち上げや注力事業のサポート、基幹システムの構築や業務セキュリティの改善など、CAグループにとって重要と思われる事象を主体的に取り組んでいます。</p>
            <p>今後、より一層のシステム化・データ化を行い、グループ全体の課題改善や生産性向上に取り組む仲間を募集します！</p>
          </Animate>
        </div>
        <figure className={styles.orgImage}>
          <Animate className="fadeIn" triggerOnce={true}>
            <div className={styles.orgHeader}>
              <CAgroupLogo role="img" aria-label="サイバーエージェントグループ" />
            </div>
            <ul className={styles.orgList}>
              <li className={styles.orgContets}>メディア</li>
              <li className={styles.orgContets}>インターネット広告</li>
              <li className={styles.orgContets}>ゲーム</li>
              <li className={styles.orgContets}>AI</li>
              <li className={styles.orgContets}>DX</li>
              <li className={styles.orgContets}>スタートアップ</li>
            </ul>
            <div className={styles.orgFooter}>
              <p className={styles.orgdescription}>システム・インフラ・セキュリティの課題解決を推進</p>
              <p className={styles.orgName}>グループIT推進本部</p>
            </div>
          </Animate>
        </figure>
      </section>
      <section className={styles.teams}>
        <div className={styles.title}>
          <Animate className="fadeIn" triggerOnce={true}>
            <h2 className="title3">チームと職種</h2>
          </Animate>
          <Animate className="fadeIn" triggerOnce={true}>
            <ul className={styles.jobList}>
              <li>
                <button
                  onClick={() => switchList('')}
                  className={`${!selectedJob ? styles.seledted : ''}`}
                >
                  すべて（{data.allMdx.totalCount}）
                </button>
              </li>
              {
                jobs.map((job, index) => (
                  <li key={index}>
                    <button
                      onClick={() => switchList(job)}
                      className={`${job === selectedJob ? styles.seledted : ''}`}
                    >
                      {job}（{jobCount[job]}）
                    </button>
                  </li>
                ))
              }
            </ul>
          </Animate>
        </div>

        <div className={teamList}>
          {
            selectedNode.map(node => (
              <section key={node.id} className={teamInfo}>
                <Animate className="fadeIn" triggerOnce={true}>
                  <header className="title4">
                    <h3>{node.frontmatter.name}</h3>
                    {
                      node.frontmatter.jobsite
                        ?
                        <p>
                          <a className={siteLink} href={node.frontmatter.jobsite} target="_blank" rel="noopener noreferrer">採用</a>
                        </p>
                        :
                        ``
                    }
                  </header>
                  <Link
                    className={`${teamLink} ${node.body ? "" : inactive}`}
                    to={`${node.frontmatter.slug}/`}
                  >
                    <figure className={teamLogo}>
                      <GatsbyImage
                        image={getImage(node.frontmatter.logo.src)}
                        alt={node.frontmatter.logo.alt}
                        placeholder="transparent"
                        loading="lazy"
                      />
                    </figure>

                    <h4 className={styles.heading}>{node.frontmatter.heading}</h4>
                    <p className={styles.description}>{node.frontmatter.description}</p>
                    {
                      node.body
                        ?
                        <p className={moreLink}><span>more info</span></p>
                        :
                        ``
                    }
                  </Link>
                  {
                    node.frontmatter.jobcategories
                      ?
                      <p className={styles.jobcategory}>
                        {
                          node.frontmatter.jobcategories.map((job, index) => (
                            <button
                              key={index}
                              onClick={() => switchList(job)}
                              className={`${job === selectedJob ? styles.seledted : ''}`}
                            >
                              {job}
                            </button>
                          ))
                        }
                      </p>
                      :
                      ``
                  }

                </Animate>
              </section>
            ))
          }
        </div>

      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx( sort: {fields: frontmatter___order, order: ASC} ) {
      nodes {
        id
        body
        frontmatter {
          name
          slug
          heading
          description
          logo {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          jobcategories
          jobsite
        }
      }
      totalCount
    }
  }
`

export const Head = () => <Seo title="チームについて" pathname="/team/" />

export default TeamPage
